<template>
  <div class="nursery-grid small-text">
    <div v-if="fromGrid" class="field-line">
      <a class="" href @click.prevent="createFromGrid">
        <i class="fa fa-clone"></i>
        Dupliquer les plages d'inscriptions depuis le dernier contrat
      </a>
    </div>
    <div class="field-line">
      <b-row>
        <b-col cols="3">
          <b-form-group label="Nombre de semaines" label-for="weeksCycle">
            <b-form-input
              type="number"
              step="1" min="1" id="weeksCycle"
              v-model="weeksCycle"
              :disabled="force"
              @change="updateGrid()"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Vacances" label-for="holidaysRule">
            <b-form-checkbox id="holidaysRule" v-model="holidaysRule" @change="updateGrid()" :disabled="force">
              Définir une règle spécifique pour les vacances
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Nombre de semaines" label-for="holidaysCycle">
            <b-form-input
              type="number"
              step="1" min="1" id="holidaysCycle"
              :disabled="!holidaysRule"
              v-model="holidaysCycle"
              @change="updateGrid()"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div class="field-line" v-for="week of weeks" :key="week.id">
      <div class="header-line" :class="week.holidays ? 'holidays' : ''">
        <b-row>
          <b-col>{{ week.name }}</b-col>
          <b-col cols="4" class="text-right">
            <a href @click.prevent="on4DaysWeek(week)" v-if="has4DaysWeek(week)">
              <i class="fa fa-square-check" v-if="is4DaysWeek(week)"></i>
              <i class="fa fa-square" v-else></i>
              4 jours
            </a>
            &nbsp;
            <a href @click.prevent="onWorkWeek(week)" v-if="hasWorkWeek(week)">
              <i class="fa fa-square-check" v-if="isWorkWeek(week)"></i>
              <i class="fa fa-square" v-else></i>
              Semaine
            </a>
            &nbsp;
            <a href @click.prevent="onFullWeek(week)">
              <i class="fa fa-square-check" v-if="isFullWeek(week)"></i>
              <i class="fa fa-square" v-else></i>
              Tous
            </a>
          </b-col>
        </b-row>
      </div>
      <div class="field-line" v-for="day of getWeekDays(week)" :key="day.id">
        <b-row>
          <b-col cols="3">
            <b-form-checkbox
              :id="'day' + day.fullId()"
              v-model="day.selected"
              @change="updateSelected(day)"
            >
              {{ getDayName(day.dayId)}}
            </b-form-checkbox>
            <div v-if="day.selected && canSetSiblings(day)">
              <a href @click.prevent="setUnsetSiblings(day)">
                <i class="fa fa-paste"></i>
                copier
              </a>
            </div>
          </b-col>
          <b-col>
            <time-picker-input
              v-model="day.arrivalAt"
              :id="'arrivalAt' + day.fullId()"
              :disabled="!day.selected"
              :error="!isArrivalAtValid(day)"
              @change="updated()"
            >
            </time-picker-input>
          </b-col>
          <b-col>
            <time-picker-input
              v-model="day.leavingAt"
              :id="'leavingAt' + day.fullId()"
              :disabled="!day.selected"
              :error="!isLeavingAtValid(day)"
              @input="timeUpdated(day)"
            ></time-picker-input>
          </b-col>
          <b-col v-if="day.tracks">
            <time-picker-input
              v-model="day.arrivalAt2"
              :id="'arrivalAt2' + day.fullId()"
              :error="!isArrivalAt2Valid(day)"
              :disabled="!day.selected"
              @input="timeUpdated(day)"
            >
            </time-picker-input>
          </b-col>
          <b-col v-if="day.tracks">
            <time-picker-input
              v-model="day.leavingAt2"
              :id="'leavingAt2' + day.fullId()"
              :error="!isLeavingAt2Valid(day)"
              :disabled="!day.selected"
              @input="timeUpdated(day)"
            ></time-picker-input>
          </b-col>
          <b-col cols="3" class="text-right">
            <b-form-checkbox
              :id="'lunch' + day.fullId()"
              v-model="day.lunch"
              :disabled="!day.selected"
              @input="timeUpdated(day)"
            >
              Repas
            </b-form-checkbox>
            <div v-if="day.selected">
              <a href @click.prevent="toggleDayTracks(day)">
                <i class="fa fa-toggle-on" v-if="day.tracks"></i>
                <i class="fa fa-toggle-off" v-else></i>
                {{ getDayTracks(day) }}
              </a>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import TimePickerInput from '@/components/Controls/TimePickerInput.vue'
import { getDayName, NurseryGridDay, NurseryGridWeek } from '@/types/nursery'
import { diffTimes } from '@/utils/time'
import { hasLunch } from '@/utils/nursery'

export default {
  name: 'nursery-inscription-grid',
  components: {
    TimePickerInput,
  },
  props: {
    contract: {
      type: Object,
      default: null,
    },
    fromGrid: {
      type: Object,
      default: null,
    },
    force: {
      type: Boolean,
      default: false,
    },
    hasSaturday: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      weeksCycle: 1,
      holidaysRule: false,
      holidaysCycle: 1,
      days: [],
    }
  },
  computed: {
    weeks() {
      const weeks = []
      let counter = 0
      for (let index = 0; index < this.weeksCycle; index++) {
        weeks.push(
          new NurseryGridWeek(++counter, index, 'Semaine ' + (index + 1), false)
        )
      }
      if (this.holidaysRule) {
        for (let index = 0; index < this.holidaysCycle; index++) {
          weeks.push(
            new NurseryGridWeek(++counter, index, 'Vacances - Semaine ' + (index + 1), true)
          )
        }
      }
      return weeks
    },
    isGridValid() {
      const selected = this.days.map(elt => elt.selected).filter(elt => elt).length
      let isValid = selected > 0
      if (isValid) {
        for (const day of this.days.filter(elt => elt.selected)) {
          isValid = (
            this.isArrivalAtValid(day) &&
            this.isLeavingAtValid(day) &&
            this.isArrivalAt2Valid(day) &&
            this.isLeavingAt2Valid(day)
          )
          if (!isValid) {
            return false
          }
        }
      }
      return isValid
    },
  },
  watch: {
    days: function() {
      this.updated()
    },
    contract: function() {
      this.onCreated()
    },
    force: function() {
      this.onCreated()
    },
  },
  created() {
    this.onCreated()
  },
  methods: {
    getDayName,
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading', 'setEditMode']),
    createFromGrid() {
      this.weeksCycle = this.fromGrid.weeksCycle
      this.holidaysRule = this.fromGrid.holidaysCycle > 0
      this.holidaysCycle = this.fromGrid.holidaysCycle
      this.updateDays()
      for (const day of this.days) {
        this.initDayItemFromGrid(day)
      }
      this.days = [].concat(this.days)
    },
    updateDays() {
      let dayIds = [0, 1, 2, 3, 4]
      if (this.hasSaturday) {
        dayIds.push(5)
      }
      const weeks = this.weeks
      let expectedCount = dayIds.length * weeks.length
      let counter = 0
      for (const week of weeks) {
        for (const dayId of dayIds) {
          if (++counter > this.days.length) {
            this.days.push(
              new NurseryGridDay(
                dayId,
                week,
                false
              )
            )
          }
        }
      }
      this.days = this.days.slice(0, expectedCount)
    },
    getWeekDays(week) {
      return this.days.filter(elt => elt.weekId() === week.id)
    },
    async onCreated() {
      if (this.contract && !this.force) {
        this.weeksCycle = this.contract.weeksCycle
        this.holidaysRule = this.contract.holidaysCycle > 0
        this.holidaysCycle = this.contract.holidaysCycle
      } else {
        this.weeksCycle = 1
        this.holidaysRule = false
        this.holidaysCycle = 0
      }
      this.updateDays()
      if (this.contract) {
        for (const day of this.days) {
          this.initDayItem(day)
        }
      }
      this.days = [].concat(this.days)
      this.updated()
    },
    initDayItemFromGrid(day) {
      if (this.fromGrid) {
        const items = this.fromGrid.items.filter(
          item => (
            (item.day === day.dayId) &&
            // (item.track === (day.tracks ? 1 : 0)) &&
            (item.weekIndex === day.weekIndex()) &&
            (item.holidays === day.holidays())
          )
        )
        for (const item of items) {
          day.selected = true
          if (item.track === 0) {
            day.arrivalAt = item.arrivalAt
            day.leavingAt = item.leavingAt
          } else {
            day.tracks = true
            day.arrivalAt2 = item.arrivalAt
            day.leavingAt2 = item.leavingAt
          }
          if (item.lunch) {
            day.lunch = items.lunch
          }
        }
      }
    },
    initDayItem(day) {
      if (this.contract) {
        const items = this.contract.items.filter(
          item => (
            (item.day === day.dayId) &&
            // (item.track === (day.tracks ? 1 : 0)) &&
            (item.weekIndex === day.weekIndex()) &&
            (item.holidays === day.holidays())
          )
        )
        for (const item of items) {
          day.selected = true
          if (item.track === 0) {
            day.arrivalAt = item.arrivalAt
            day.leavingAt = item.leavingAt
          } else {
            day.tracks = true
            day.arrivalAt2 = item.arrivalAt
            day.leavingAt2 = item.leavingAt
          }
          if (item.lunch) {
            day.lunch = items.lunch
          }
        }
      }
      return null
    },
    updateGrid() {
      this.updateDays()
    },
    updated() {
      this.$emit(
        'change', {
          weeksCycle: this.weeksCycle,
          holidaysCycle: this.holidaysRule ? this.holidaysCycle : 0,
          days: this.days,
          isValid: this.isGridValid,
        }
      )
    },
    setLunch(day) {
      // Vérifie la présence à l'heure du repas et coche la case repas si besoin
      if (day.selected) {
        day.lunch = hasLunch(
          day.arrivalAt, day.leavingAt, day.tracks, day.arrivalAt2, day.leavingAt2
        )
        this.days = [].concat(this.days)
      }
    },
    timeUpdated(day) {
      this.setLunch(day)
      this.updated()
    },
    getDayTracks(day) {
      return day.tracks ? '2 plages' : '1 plage'
    },
    toggleDayTracks(day) {
      day.tracks = !day.tracks
      // this.days = [].concat(this.days)
      this.updated()
    },
    onFullWeek(week) {
      const selected = this.isFullWeek(week)
      for (const day of this.getWeekDays(week)) {
        day.selected = !selected
      }
      this.days = [].concat(this.days)
      this.updated()
    },
    onWorkWeek(week) {
      const selected = this.isWorkWeek(week)
      for (const day of this.getWeekDays(week)) {
        if (day.dayId < 5) {
          day.selected = !selected
        }
      }
      this.days = [].concat(this.days)
      this.updated()
    },
    hasWorkWeek(week) {
      return this.getWeekDays(week).length > 5
    },
    isWorkWeek(week) {
      for (const day of this.getWeekDays(week)) {
        if (!day.selected && day.dayId < 5) {
          return false
        }
      }
      return true
    },
    has4DaysWeek(week) {
      return this.getWeekDays(week).filter(day => day.dayId === 2).length > 0
    },
    on4DaysWeek(week) {
      const selected = this.is4DaysWeek(week)
      for (const day of this.getWeekDays(week)) {
        if ((day.dayId < 5) && (day.dayId !== 2)) {
          day.selected = !selected
        }
      }
      this.days = [].concat(this.days)
      this.updated()
    },
    is4DaysWeek(week) {
      for (const day of this.getWeekDays(week)) {
        if (!day.selected && (day.dayId < 5) && (day.dayId !== 2)) {
          return false
        }
      }
      return true
    },
    isFullWeek(week) {
      for (const day of this.getWeekDays(week)) {
        if (!day.selected) {
          return false
        }
      }
      return true
    },
    isArrivalAtValid(day) {
      if (day.selected) {
        return day.arrivalAt
      }
      return true
    },
    isLeavingAtValid(day) {
      if (day.selected) {
        if (day.arrivalAt && day.leavingAt) {
          return diffTimes(day.leavingAt, day.arrivalAt) > 0
        }
        return false
      }
      return true
    },
    isArrivalAt2Valid(day) {
      if (day.selected && day.tracks) {
        if (day.arrivalAt2 && day.leavingAt) {
          return diffTimes(day.arrivalAt2, day.leavingAt) > 0
        }
        return false
      }
      return true
    },
    isLeavingAt2Valid(day) {
      if (day.selected && day.tracks) {
        if (day.arrivalAt2 && day.leavingAt2) {
          return diffTimes(day.leavingAt2, day.arrivalAt2) > 0
        }
        return false
      }
      return true
    },
    setUnsetSiblings(day) {
      if (day.selected) {
        let isSet = (day.arrivalAt && day.leavingAt)
        if (isSet && day.tracks) {
          isSet = (day.arrivalAt2 && day.leavingAt2)
        }
        if (isSet) {
          let unsets = this.getUnsetSiblings(day)
          for (const elt of unsets) {
            let changed = false
            if (!elt.arrivalAt) {
              elt.arrivalAt = day.arrivalAt
              changed = true
            }
            if (!elt.leavingAt) {
              elt.leavingAt = day.leavingAt
              changed = true
            }
            if (day.tracks && elt.tracks) {
              if (!elt.arrivalAt2) {
                elt.arrivalAt2 = day.arrivalAt2
                changed = true
              }
              if (!elt.leavingAt2) {
                elt.leavingAt2 = day.leavingAt2
                changed = true
              }
            }
            if (changed) {
              this.setLunch(elt)
            }
          }
        }
      }
      this.days = [].concat(this.days)
      return false
    },
    updateSelected(day) {
      this.days = [].concat(this.days)
      this.updated()
    },
    canSetSiblings(day) {
      if (day.selected) {
        let isSet = !!(day.arrivalAt && day.leavingAt)
        if (isSet && day.tracks) {
          isSet = !!(day.arrivalAt2 && day.leavingAt2)
        }
        if (isSet) {
          let unsets = this.getUnsetSiblings(day)
          if (unsets.length) {
            return true
          }
        }
      }
      return false
    },
    getUnsetSiblings(day) {
      return this.getWeekDays(day.week).filter(
        elt => (
          elt.selected &&
          (elt.dayId !== day.dayId) &&
          (
            !elt.arrivalAt ||
            !elt.leavingAt ||
            (elt.tracks && !elt.arrivalAt2) ||
            (elt.tracks && !elt.leavingAt2)
          )
        )
      )
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.nursery-grid {
  .header-line.holidays {
    background: #ff5500;
  }

  .header-line a {
    color: #fff !important;
    text-decoration: none;
  }
}
</style>
